import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link, Link as RouterLink } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}> {/* Centrar el contenido */}
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: '2rem',
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            marginBottom: '2rem',
          }}
        >
          Términos y condiciones sitio web
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Generalidades
        </Typography>
        
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Milockers es una tienda online, dedicada a la venta de una amplia variedad de productos, como 
          juguetes, lockers, mobiliario para oficinas, y muebles para el hogar. 
          Debido a que los presentes Términos y Condiciones son vinculantes entre Milockers y sus usuarios, y 
          se comprenden aceptados por estos últimos desde el momento en que acceden el sitio, 
          recomendamos leerlos detenidamente. Las eventuales nuevas funciones, herramientas o servicios 
          que se agreguen posteriormente al sitio actual, también se encontrarán sujetas a los presentes
          términos. Asimismo, nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte 
          de los presentes términos del sitio mediante la publicación de actualizaciones o cambios en nuestra 
          página web, siendo responsabilidad del usuario revisar este contenido periódicamente.
          Los encabezados utilizados en este acuerdo se incluyen solo para facilitar su lectura, razón por la 
          cual, no limitarán ni afectarán la interpretación del presente documento.
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Términos del sitio web
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Podrán utilizar el sitio y comprar productos, personas mayores de edad que tengan capacidad legal 
          para contratar. Asimismo, no se podrá utilizar nuestro sitio o productos para fines ilegítimos, ya sea, 
          infringir leyes o reglamentos, incluyendo de manera enunciativa más no limitativa, derechos de 
          autor, propiedad intelectual, información confidencial propia o ajena, datos personales, 
          antecedentes bancarios o información confidencial, ni transmitir ningún gusano o virus informático 
          ni ningún código de naturaleza destructiva, entre otros.
          Nos reservamos el derecho de denegar la venta de productos a cualquier persona o usuario, por 
          cualquier motivo, en cualquier momento, lo cual en ningún caso constituirá un acto discriminatorio, 
          si no, la libre decisión de rechazar encargos que representen dificultades técnicas o éticas a la 
          empresa.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Registro
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Quien desee comprar por intermedio de nuestra página, deberá completar el formulario de 
          información requerido, con todos los datos del cliente que sean necesarios para autentificar la 
          compra y entrega del producto. De este modo, el usuario se compromete a completar la 
          información de manera exacta, precisa y verdadera y a proporcionarsus datos siempre actualizados. 
          Sin perjuicio de la información brindada en el formulario, eventualmente podremos solicitar y/o 
          consultar información adicional para corroborar la identidad del suscribiente.
          El pago de los productos se realizará mediante la plataforma de Mercado Pago, empresa que 
          dispone de sus propios Términos y Condiciones de Servicios. 
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Modificaciones de precios y servicios de entrega
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Los precios de nuestros productos o modalidades de despachos o entregas, están sujetos a cambios 
          sin previo aviso. Sin embargo, estas modificaciones solo afectarán a los productos adquiridos con 
          posterioridad al cambio. 
          Asimismo, nos reservamos el derecho de modificar o descontinuar determinados productos en 
          cualquier momento.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Propiedad de los productos, patentes o marcas
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Nos reservamos el derecho de limitar o prohibir la venta de productos que, a nuestra discreción, 
          parezcan ser requeridos por comerciantes, revendedores o distribuidores que vulneren derechos 
          de propiedad intelectual, marcas o registros, licencias, entre otros, o que simplemente no puedan 
          acreditar la titularidad sobre los mismos.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Enlaces de terceros
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Algunos contenidos y/o servicios disponibles pueden incluir recursos de terceros. Dichos enlaces en 
          este sitio pueden dirigirlo a páginas web de terceros que no están afiliados a nosotros, razón por la 
          cual, no somos responsables de examinar o evaluar su contenido y exactitud, ni garantizar ninguna 
          obligación o responsabilidad por los recursos, materiales, servicios, productos, Términos y 
          Condiciones propios o páginas web de terceros.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Comentarios de los usuarios y comunicaciones libres de violencia
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El usuario es el único responsable de los comentarios que realice, sin que Milockers asuma alguna 
          responsabilidad u obligación por los comentarios publicados. Además, el usuario acepta que sus 
          comentarios no contendrán material difamatorio, ilegítimo, abusivo u obsceno, violento, ni 
          contendrán ningún virus informático ni otro software dañino. 
          Asimismo, el usuario reconoce mantener un trato cordial y respetuoso con todos los colaboradores 
          de Milockers, así como respecto de aquellos que se desempeñan en sus empresas relacionadas, en el 
          marco de la protección de un ambiente laboral libre de violencia en el trabajo.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Información personal y confidencialidad
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El envío de la información personal que haga a través del sitio o por canales de comunicación 
          internos de Milockers, se rige por normas de privacidad y por la legislación vigente y futura sobre 
          protección de datos personales. Es por ello, que los datos ingresados por los usuarios no serán 
          entregados a terceros, salvo que deban ser revelados en cumplimiento de una orden judicial o un 
          requerimiento legal.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Errores, inexactitudes y omisiones
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Puede haber información en nuestro sitio, que involuntariamente, contenga errores tipográficos, 
          inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios, 
          promociones, ofertas y disponibilidad. Nos reservamos el derecho de corregir cualquier error, 
          inexactitud u omisión y de cambiar o actualizar dicha información en nuestro sitio web, en cualquier 
          momento, sin previo aviso.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Usos prohibidos
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Se le prohíbe a los usuarios utilizar el sitio o su contenido para cualquier propósito ilegal; para 
          infringir cualquier reglamento, norma, ley u ordenanza nacional o internacional; para vulnerar
          derechos de propiedad intelectual; violentar, intimidar o discriminar; cargar o transmitir virus o 
          cualquier otro tipo de código dañino que afecte o pueda afectar a la funcionalidad o el 
          funcionamiento del servicio o de cualquier sitio web relacionado, de otros sitios web o de Internet; 
          recopilar o rastrear información personal de otros; o para interferir o eludir las funciones de 
          seguridad del servicio o de cualquier sitio web relacionado, o de otros sitios web o de Internet; 
          siendo todo lo señalado, meramente enunciativo.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Limitación de responsabilidad
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          No garantizamos, representamos ni aseguramos que el uso que haga de nuestra página será sin 
          interrupciones, oportuno, seguro o sin errores ya que en gran medida éstos dependen de otras 
          plataformas y de la correcta conexión a la red. Milockers no garantiza la ausencia de códigos 
          maliciosos ni de otros elementos en aplicaciones introducidas por terceros ajenos a la organización, 
          que puedan producir alteraciones en los sistemas del usuario o en los documentos electrónicos y 
          ficheros de almacenamiento en sus sistemas. De este modo, no se responsabiliza de los daños que 
          pudieran derivarse de ellos.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Indemnización
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Usted acepta indemnizar, defender y mantener indemne a Milockers y a nuestra empresa matriz, 
          subsidiarias, afiliadas, asociados, funcionarios, directores, agentes, contratistas, licenciantes, 
          proveedores de servicios, subcontratistas, proveedores y colaboradores; de cualquier reclamo o 
          demanda, incluidos los honorarios razonables de abogados, en los que un tercero haya incurrido 
          debido a su incumplimiento de los presentes Términos del Sitio vigentes o actualizados, o por la 
          violación de cualquier ley o derechos de terceros.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Horarios
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Queda establecido que nuestro horario de atención a clientes y usuarios es de 9:30 a 18:00 horas 
          de lunes a jueves, y viernes, de 9:30 a 14:00 horas. Sin embargo, se atenderán fuera de ese horario, 
          sólo situaciones consideradas urgentes e imprevistas, cuya valoración de la urgencia será estimada 
          discrecionalmente por nuestro equipo de colaboradores.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Acuerdo completo
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El hecho de que no ejerzamos o hagamos valer algún derecho o disposición de los presentes 
          Términos del Sitio no constituirá una renuncia a dicho derecho o disposición.
          Los presentes Términos y Condiciones del Sitio, así como cualquier política que publiquemos en el
          sitio constituye el acuerdo y entendimiento completo entre el usuario y UnLoop, sustituyendo a 
          cualquier comunicación anterior o contemporánea, ya sea oral o escrita, entre usted y nosotros.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Ley aplicable
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Los presentes Términos del Sitio, así como nuestros productos y servicios,se regirán e interpretarán 
          conforme a la legislación chilena.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Información de contacto
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Las preguntas sobre los Términos del Sitio y de Servicios se deben enviar a{' '}
          <Link
            component={RouterLink}
            to="/contacto"
            sx={{
              color: 'blue', // Cambia el color del enlace a azul
              textDecoration: 'underline', // Sube una línea debajo para mostrar que es un enlace
              '&:hover': {
                color: 'darkblue', // Cambia el color al pasar el mouse
                textDecoration: 'none', // Elimina el subrayado al pasar el mouse
              },
            }}
          >
            contacto@milockers.cl
          </Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
