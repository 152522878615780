import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

function Rechazado() {
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el proceso ha pasado por el Redirector
    const paymentStatus = sessionStorage.getItem('payment_status');

    if (paymentStatus !== 'rejected') {
      // Redirigir a la página principal si no se ha rechazado la compra
      navigate('/');
    }

    // Eliminar el estado después de la visualización
    sessionStorage.removeItem('payment_status');

    // Redirigir a la página principal después de 5 segundos
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    // Limpiar el temporizador cuando el componente se desmonte
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h4" color="error.main">
        ¡Compra rechazada!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Hubo un problema con su compra. Por favor, inténtelo nuevamente.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
        Será redirigido a la página principal en unos momentos...
      </Typography>
    </div>
  );
}

export default Rechazado;
