import React, { useState } from 'react';
import { TextField, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { validarRut } from '../../Utils/validarRut';

const DatosEntrega = ({
    direccion,
    correo,
    nombre,
    rut,
    telefono,
    facturacion,
    // precioVisible,
    // comunaPrecio,
    handleDireccionChange,
    handleCorreoChange,
    handleNombreChange,
    handleRutChange,
    handleTelefonoChange,
    handleRutValidoChange,
    handleTelefonoValidoChange,
    handleCheckboxChange,
    rutEmpresa,
    setRutEmpresa,
    rutInvalidoEmpresa,
    setRutInvalidoEmpresa,
    rutValidoEmpresa,
    setRutValidoEmpresa,
    razonSocial,
    setRazonSocial,
    giro,
    setGiro,
    direccionEmpresa,
    setDireccionEmpresa,
    envio,
  
}) => {
    const [rutInvalido, setRutInvalido] = useState(false);
    const [telefonoInvalido, setTelefonoInvalido] = useState(false);

    const formatRut = (rut) => {
        if (!rut) return '';
        rut = rut.replace(/[^0-9kK]/g, '');
        rut = rut.replace(/k/g, 'K');
        return rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1)}` : rut;
    };

    const handleFormattedRutChange = (event) => {
        const formattedRut = formatRut(event.target.value);

        handleRutChange({ ...event, target: { ...event.target, value: formattedRut } });

        if (validarRut(formattedRut)) {
            setRutInvalido(false);
            handleRutValidoChange(true);
        } else {
            setRutInvalido(true);
            handleRutValidoChange(false);
        }
    };

    const handleFormattedRutEmpresaChange = (event) => {
        const formattedRut = formatRut(event.target.value);
        setRutEmpresa(formattedRut);

        if (validarRut(formattedRut)) {
            setRutInvalidoEmpresa(false);
            setRutValidoEmpresa(true);
        } else {
            setRutInvalidoEmpresa(true);
            setRutValidoEmpresa(false);
        }
    };

    const handleTelefonoFormattedChange = (event) => {
        const value = event.target.value;

        if (/^\d{0,9}$/.test(value)) {
            handleTelefonoChange(event);
            setTelefonoInvalido(value.length !== 8);
            handleTelefonoValidoChange(value.length === 8);
        } else {
            setTelefonoInvalido(true);
            handleTelefonoValidoChange(false);
        }
    };

    return (
        <Box sx={{ mt: 2, maxWidth: '800px', margin: 'auto' }}>
            <TextField
                id="nombre"
                label="Nombre Completo"
                variant="outlined"
                fullWidth
                value={nombre}
                onChange={handleNombreChange}
                placeholder="Ej: Juan Pérez"
                required
                InputProps={{
                    sx: {
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                sx={{ mb: 2 }}
            />
            <TextField
                id="rut"
                label="RUT"
                variant="outlined"
                fullWidth
                value={rut}
                onChange={handleFormattedRutChange}
                placeholder="Ej: 12.345.678-9"
                required
                error={rutInvalido}
                helperText={rutInvalido ? "RUT no válido" : ""}
                InputProps={{
                    sx: {
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                sx={{ mb: 2 }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography
                    variant="body1"
                    sx={{
                        mr: 1,
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}
                >
                    +569
                </Typography>
                <TextField
                    id="telefono"
                    label="Teléfono"
                    variant="outlined"
                    fullWidth
                    value={telefono}
                    onChange={handleTelefonoFormattedChange}
                    placeholder="12345678"
                    required
                    error={telefonoInvalido}
                    helperText={telefonoInvalido ? "El número debe tener exactamente 8 dígitos" : ""}
                    InputProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        }
                    }}
                />
            </Box>
            <TextField
                id="correo"
                label="Correo"
                variant="outlined"
                fullWidth
                value={correo}
                onChange={handleCorreoChange}
                placeholder="Ej: ejemplo@correo.com"
                required
                InputProps={{
                    sx: {
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                sx={{ mb: 2 }}
            />
            {envio !== 'Retiro' && (
                <>
                    <TextField
                        id="direccion"
                        label="Dirección"
                        variant="outlined"
                        fullWidth
                        value={direccion}
                        onChange={handleDireccionChange}
                        placeholder="Ej: Av. Siempre Viva 742"
                        required
                        InputProps={{
                            sx: {
                                fontFamily: 'Bree Serif, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontFamily: 'Bree Serif, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }
                        }}
                        sx={{ mb: 2 }}
                    />
                </>
            )}

            <FormControlLabel
                control={
                <Checkbox
                    checked={facturacion}
                    onChange={handleCheckboxChange}
                    color="primary"
                />
                }
                label={
                <Typography
                    sx={{
                    fontFamily: 'Bree Serif, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                    Agregar datos de facturación
                </Typography>
                }
            />

            {facturacion && (
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 700,
                        textAlign: 'center',
                        mt: 2,
                }}
                >
                    Datos de facturación
                </Typography>
            )}

            {facturacion && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
                    <TextField
                        label="Rut de empresa"
                        value={rutEmpresa}
                        onChange={handleFormattedRutEmpresaChange}
                        error={rutInvalidoEmpresa}
                        helperText={rutInvalidoEmpresa ? 'RUT inválido' : ''}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
                    <TextField
                        label="Razón social"
                        value={razonSocial}
                        onChange={(e) => setRazonSocial(e.target.value)}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
                    <TextField
                        label="Giro"
                        value={giro}
                        onChange={(e) => setGiro(e.target.value)}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
                    <TextField
                        label="Dirección de empresa"
                        value={direccionEmpresa}
                        onChange={(e) => setDireccionEmpresa(e.target.value)}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Bree Serif, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
            </Box>
            )}


            {/* {precioVisible && comunaPrecio !== null && (
                <Typography
                    sx={{
                        mt: 2,
                        fontFamily: 'Bree Serif, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}
                >
                    Precio de envío: ${comunaPrecio}
                </Typography>
            )} */}
        </Box>
    );
};

export default DatosEntrega;
