import React, { useState, useContext } from 'react';
import { Box, Typography, Snackbar } from '@mui/material';
import ItemCount from './Itemcount';
import { toCapital } from '../../Helpers/toCapital';
import { CartContext } from '../../Context/CardContext';

const FALLBACK_IMAGE = 'https://milockers.cl/images/Imagen_no_disponible.jpg';

const ItemDetail = ({ item }) => {
  const [cantidad, setCantidad] = useState(1);
  const [agregadoAlCarrito, setAgregadoAlCarrito] = useState(false);
  const [errorStock, setErrorStock] = useState(false);
  const { agregarAlCarrito } = useContext(CartContext);
  const [imagenPrincipal, setImagenPrincipal] = useState(item.imagen);

  // Array que incluye la imagen principal como parte de las miniaturas.
  const miniaturas = [
    { id: 'principal', imagen: item.imagen }, // Imagen principal como la primera miniatura.
    ...(item.miniaturas || []),
  ];

  const handleRestar = () => {
    cantidad > 1 && setCantidad(cantidad - 1);
  };

  const handleSumar = () => {
    if (cantidad < item.stock) {
      setCantidad(cantidad + 1);
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  };

  const handleAgregarAlCarrito = () => {
    const resultado = agregarAlCarrito(item, cantidad);
    if (resultado.success) {
      setAgregadoAlCarrito(true);
      setTimeout(() => {
        setAgregadoAlCarrito(false);
      }, 3000);
    } else {
      setErrorStock(true);
      setTimeout(() => {
        setErrorStock(false);
      }, 3000);
    }
  };

  const handleImageError = () => {
    setImagenPrincipal(FALLBACK_IMAGE);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        margin: '4rem 0',
        '@media (min-width: 768px)': {
          flexDirection: 'row',
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <img
            src={imagenPrincipal}
            onError={handleImageError}
            alt={item.titulo}
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Box>
        {miniaturas.length > 1 && (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center', marginTop: '1rem' }}>
            {miniaturas.map((miniatura) => (
              <img
                key={miniatura.id}
                src={miniatura.imagen}
                alt={`Miniatura ${miniatura.id}`}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  border: miniatura.imagen === imagenPrincipal ? '2px solid #000' : 'none',
                  borderRadius: '4px',
                }}
                onClick={() => setImagenPrincipal(miniatura.imagen)}
                onError={(e) => (e.target.src = FALLBACK_IMAGE)}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: '1.5rem',
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          {item.titulo}
        </Typography>
        <Typography 
          variant="body2"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem'
          }}
        >
          Categoría: {toCapital(item.categoria)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem'
          }}
        >
          SKU: {toCapital(item.modelo)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            textAlign: 'justify',
            textAlignLast: 'left',
            textJustify: 'inter-word',
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{ __html: item.descripcion }}
        />
        <Typography variant="h5" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
          {formatPrice(item.precio)}
        </Typography>
        <ItemCount
          cantidad={cantidad}
          handleSumar={handleSumar}
          handleRestar={handleRestar}
          handleAgregar={handleAgregarAlCarrito}
          stock={item.stock}
        />
        <Snackbar
          open={agregadoAlCarrito}
          autoHideDuration={3000}
          onClose={() => setAgregadoAlCarrito(false)}
          message={`Se han agregado ${cantidad} ${item.titulo} al carrito.`}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
        <Snackbar
          open={errorStock}
          autoHideDuration={3000}
          onClose={() => setErrorStock(false)}
          message="No se puede añadir más de lo disponible en stock"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
      </Box>
    </Box>
  );
};

export default ItemDetail;
